
import {computed, defineComponent, onMounted, ref} from "vue";
import {useMetricValueStore} from "@/store/metric.valute.store";
import {
  GetMetricFormName,
  GetSpaceId,
  LoadPanel,
  MetricGroupsSelect,
  PinaFilterObjects
} from "@/core/composite/composite";
import Entities from "@/components/base/common/Entities.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import UserListSelect from "@/components/common/UserListSelect.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import MetricValueUpdateForm from "@/views/metric/MetricValueUpdateForm.vue";
import Swal from "sweetalert2";
import MetricValueService from "@/core/services/MetricValueService";
import MetricValueFormat from "@/views/metric/value/MetricValueFormat.vue";
import UserLink from "@/views/user/UserLink.vue";
import MetricName from "@/views/metric/MetricName.vue";

export default defineComponent({
  name: "TeamMetricValues",
  components: {
    MetricName,
    UserLink,
    MetricValueFormat,
    MetricValueUpdateForm,
    BaseModal,
    QuickAction,
    UserListSelect, DateTimeFormat, KtDatatable, BaseSelect, Entities
  },
  setup() {
    const state = ref({id: '', value: {amount: ''}})
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Team Metrics",
        [
          {link: false, router: '', text: 'Team Metrics'}
        ]
      )
    });
    const metricValueStore = useMetricValueStore();
    const page = computed(() => metricValueStore.page);
    const filterObjects = ref({groupId: '', team: true, list: true, assigneeId: '', isComplete: false});
    const headers = [

      {
        name: 'Date',
        key: 'date',
        sortable: false
      },
      {
        name: 'Metric',
        key: 'metric',
        sortable: false
      },
      {
        name: 'Assigned',
        key: 'assigned',
        sortable: false
      },
      {
        name: 'Value',
        key: 'value',
        sortable: false
      },
      {
        name: '',
        key: 'actions',
        sortable: false
      }
    ]
    return {
      state,
      ...GetSpaceId(),
      ...MetricGroupsSelect(),
      ...GetMetricFormName(),
      page,
      headers,
      filterObjects,
      ...LoadPanel(),
      metricValueStore,
      ...PinaFilterObjects(metricValueStore, filterObjects.value, ['metric', 'reportBy'])
    }
  },
  methods: {
    searchEvent() {
      this.updateFilterObject(this.filterObjects);
    },
    discardEvent() {
      this.filterObjects.groupId = '';
      this.updateFilterObject(this.filterObjects);
    },
    update(row) {
      this.state.id = row.id;
      this.state.value.amount = row.amount;
      const base = this.$refs.metricUpdateValueListRef as typeof BaseModal;
      base.showBaseModal();
    },
    onSave() {
      const base = this.$refs.metricUpdateValueListRef as typeof BaseModal;
      base.hideBaseModal();
      this.paginationDataLoad();
      this.$emit('updateValue')
    },
    deleteValue(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await MetricValueService.delete(id).then(() => {
            this.paginationDataLoad()
          })
        }
      })
    },
    sendEmail(value) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      MetricValueService.sendEmail(value.id).then(() => {
      }).finally(() => {
        Swal.close();
      })
    }
  }
})
